// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	partnerID: '595166383625c506d4868f28',
	progMenu: 'https://programamenu-stg.tagme.com.br',
	channelsAPI: {
		baseURL: 'https://channelsapi-dev.tagme.com.br/api/v1/',
		token: 'ep5xycuir9s382ka258bckxdner82f4485c2y6ip9plvlhbgmh4xlxy5x1ejmjed',
	},
	giftURL: 'https://giftcardtagme-dev.azurewebsites.net',
}
